import React from 'react';
import '../assets/css/main.css';
import pseudo from '../images/pseudo-localization.jpg';
import profile from '../images/profile.jpg';
import goNature from '../images/goNature.jpg';
import connexion from '../images/connexion-mockup-bg.jpg';
import tvRepositorium from '../images/tv-repositorium.jpg';
import web_localizer from '../images/pseudolocalized1_ltr.PNG';

export default function main() {
  return (
    <div>
      <nav id='nav'>
        <ul className='container'>
          <li>
            <a className='icon solid fa-home' href='#top'>
              <span className='nav-text'>Introduction</span>
            </a>
          </li>
          <li>
            <a href='#portfolio' className='icon solid fa-th-large'>
              <span className='nav-text'>Portfolio</span>
            </a>
          </li>
          <li>
            <a
              className='icon brands fa-linkedin'
              href='https://www.linkedin.com/in/juyean-lee/'
            ></a>
          </li>
          <li>
            <a
              className='icon brands fa-github'
              href='https://github.com/juyeana'
            ></a>
          </li>
        </ul>
      </nav>

      <article id='top' className='wrapper style1'>
        <div className='container'>
          <div className='row'>
            <div className='col-4 col-5-large col-12-medium' id='skills'>
              <span className='image fit'>
                <img src={profile} alt='' />
              </span>
              <strong>Globalization Engineer</strong>
              <br />
              Localization Methdology and Processes, Project Management,
              Computer Assisted Translation (CAT) tools, Agile Methdology
              <br />
              <br />
              Javascript, Node.js, React, HTML, CSS
            </div>

            <div className='col-8 col-7-large col-12-medium'>
              <header id='heading'>
                <h2>Hi, I'm Juyean Lee.</h2>
              </header>
              <p id='introduction'>
                I am a driven Globalization Engineer known for delivering
                impactful enterprise solutions through localization, software
                development, and technical leadership. I demonstrate my
                globalization and localization expertise by collaborating with
                diverse teams, to transform business cases into successful
                international markets.
              </p>
              <p>
                My extensive experience covers localization engineering,
                automation, program management, and innovative problem-solving,
                resulting in business growth and enhanced efficiency across
                diverse localization strategies in software engineering. I
                consistently ensure world-ready products and enable global
                business expansion.
              </p>
            </div>
          </div>
        </div>
      </article>
      <article id='portfolio' className='wrapper style3'>
        <div>
          <h2>Portfolio</h2>
        </div>
        <div className='container'>
          <div className='row row-cols-1 row-cols-md-2 g-4'>
            {/* card 1 */}
            <div className='col'>
              <div className='card h-100'>
                <a
                  href='https://www.npmjs.com/package/web-pseudo-localizer'
                  className='image featured'
                >
                  <img src={web_localizer} alt='' />
                </a>
                <div className='card-body'>
                  <h3 className='card-title'>
                    Webpage Localizability Testing API
                  </h3>
                  <p className='card-text'>
                    Pseudo-localization API to verify webpage localizability in
                    real-time
                  </p>
                  <p>
                    <b>Technical aspect:</b> React, Node.js, CSS
                  </p>
                  <p className='link'>
                    <a href='https://www.npmjs.com/package/web-pseudo-localizer'>
                      visit website{' '}
                      <i className='fas fa-chevron-circle-right'></i>
                    </a>
                    &nbsp;&nbsp;&nbsp;{' '}
                    <a href='https://github.com/juyeana/web-pseudo-localizer'>
                      view code <i className='fas fa-chevron-circle-right'></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* card 2 */}
            <div className='col'>
              <div className='card h-100'>
                <a
                  href='https://pseudo-localization.herokuapp.com/'
                  className='image featured'
                >
                  <img src={pseudo} alt='' />
                </a>
                <div className='card-body'>
                  <h3 className='card-title'>
                    Pseudo Localization Character Generator
                  </h3>
                  <p className='card-text'>
                    Web-based tool for generating pseudo characters for pseudo
                    localization testing
                  </p>
                  <p>
                    <b>Technical aspect:</b> React, Node.js, CSS
                  </p>
                  <p className='link'>
                    <a href='https://pseudo-localization.herokuapp.com/'>
                      visit website{' '}
                      <i className='fas fa-chevron-circle-right'></i>
                    </a>
                    &nbsp;&nbsp;&nbsp;{' '}
                    <a href='https://github.com/juyeana/pseudoLocalization'>
                      view code <i className='fas fa-chevron-circle-right'></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* card 3 */}
            <div className='col'>
              <div className='card h-100'>
                <a
                  href='https://github.com/juyeana/goNature2'
                  className='image featured'
                >
                  <img src={goNature} alt='' />
                </a>
                <div className='card-body'>
                  <h3 className='card-title'>goNature</h3>
                  <p className='card-text'>Vacation package booking app</p>
                  <p>
                    <b>Technical aspect:</b> React, Redux, Node.js, MongoDB,
                    Express, HTML, CSS, SASS
                  </p>
                  <p className='link'>
                    <a href='https://github.com/juyeana/goNature2'>
                      view code <i className='fas fa-chevron-circle-right'></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* card 4 */}
            <div className='col'>
              <div className='card h-100'>
                <a
                  href='https://github.com/web-developement-2020/Connexion'
                  className='image featured'
                >
                  <img src={connexion} alt='' />
                </a>
                <div className='card-body'>
                  <h3 className='card-title'>Connexion</h3>
                  <p className='card-text'>Photo sharing social media app</p>
                  <p>
                    <b>Technical aspect:</b> React, Redux, Node.js, MongoDB,
                    Express, HTML, CSS
                  </p>
                  <p className='link'>
                    <a href='https://github.com/web-developement-2020/Connexion'>
                      view code <i className='fas fa-chevron-circle-right'></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* card 5 */}
            <div className='col'>
              <div className='card h-100'>
                <a
                  href='https://github.com/web-developement-2020/tv-maze-app'
                  className='image featured'
                >
                  <img src={tvRepositorium} alt='' />
                </a>
                <div className='card-body'>
                  <h3 className='card-title'>TV Repositorium</h3>
                  <p className='card-text'>
                    TV guide application utilizing TV-Maze API
                  </p>
                  <p>
                    <b>Technical aspect:</b> Angular, Typescript, Angular
                    Material UI, HTML, CSS
                  </p>

                  <p className='link'>
                    <a href='https://github.com/web-developement-2020/tv-maze-app'>
                      view code <i className='fas fa-chevron-circle-right'></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <p className='footer-1'>
        &copy; {new Date().getFullYear()}{' '}
        <a href='mailto:juyean.lee@jlee.app'>Juyean Lee</a>{' '}
      </p>
    </div>
  );
}
